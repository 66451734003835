/// <reference path="../site.scss" />

.collapsing {
  //.wrap-collapse {
      //margin-left: -#{$navbar-padding-x} !important;
  //}
  transition: height .5s ease-in-out;
}

// .navbar-collapse.collapsing .wrap-collapse,
// .navbar-collapse.collapse.show .wrap-collapse,
// .navbar-collapse.collapse .wrap-collapse {
//   margin-left: -#{$navbar-padding-x} !important;
//   width: 50% !important;
//   background-color: gray !important;
// }

.navbar-logo, .navbar-beeldlogo {
    padding-bottom: $grid-gutter-width/2;
    height: calc(45px + #{$grid-gutter-width/2});
}

.navbar-logo {
    display: none;
    @include media-breakpoint-up(md) {
        display: inline;
    }
}
.navbar-beeldlogo {
    @include media-breakpoint-up(md) {
        display: none;
    }
}

.navbar.c-nav-ecommaz {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  color: $brand-dark;
  background-color: $white;
  transition: transform .2s ease, background-color .3s ease;
  z-index: $zindex-fixed;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, .1);

  @include media-breakpoint-up(lg) {
    &.before-scroll {
      background-color: transparent !important;
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 0); 
    }
  }

  &.is-hidden {
    transform: translateY(-100%);
  }

  .nav-item .nav-link {
    color: $brand-dark;
    font-size: 14px;
    font-weight: $font-weight-bold;

    &.active {
      color: $brand-primary;
    }

    @include media-breakpoint-down(md) {
      &::before {
        margin-right: .5rem;
        display: inline-block;
        content: '\f105';
        color: $brand-primary;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
      }
    }

    @include media-breakpoint-only(md) {
      @include font-size(26px);
    }
  }

  .nav-item.active a,
  .navbar-nav .nav-item:last-of-type .nav-link {
    color: $brand-primary;
    transition: color .3s ease;

    &:hover {
      color: $white;
      background-color: $brand-primary;
    }
  }

  .navbar-nav .nav-item:last-of-type .nav-link {
    padding: $btn-padding-y $btn-padding-x;
    text-align: center;
    border: 2px solid $brand-primary;
    border-radius: $btn-border-radius;
  }
}

.c-nav-ecommaz {
    .navbar-brand {
        margin-left: $grid-gutter-width;
        margin-right: 0;
        padding: $grid-gutter-width/2 0 0;
    }
    > .btn.btn-outline-primary {
        margin-left: auto;
        width: auto;
        border-width: 2px;
        color: $brand-primary;
        font-weight: $font-weight-bold;
        &:hover {
            color: $white;
        }
        @include media-breakpoint-up(lg) {
            display: none;
        }
        @include media-breakpoint-down(xs) {
            span { display: none; }
        }
    }

    .wrap-collapse {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include media-breakpoint-down(md) {
          overflow: auto;
        }
        .navbar-nav {
            padding: $grid-gutter-width/2;
            @include media-breakpoint-down(md) {
                > li:last-of-type {
                    display: none;
                }
            }
        }
        .navbar-footer {
            padding: 185px $grid-gutter-width/2 $grid-gutter-width/2;
            @media only screen and (max-height:680px) {
                padding-top: 140px;
            }
            background-color: white;
            background-image: url('/img/backgrounds/bg-locator.svg'), url('/img/backgrounds/bg-light.svg');
            background-position: center top, center top;
            background-repeat: no-repeat, no-repeat;
            background-size: auto 200px, 100% 60px;
            
            text-align: center;
            img {
                display: block;
                margin: 0 auto;
                height: 77px;
            }
            a {
                margin-top: $grid-gutter-width/2;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .c-nav-ecommaz .wrap-collapse {
        background-color: $brand-light;
    }
}

@include media-breakpoint-up(lg) {
    .navbar-collapse {
        margin-right: $grid-gutter-width/2;
    }
}
