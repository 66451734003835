
$font-family-sans-serif: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"!default;

$section-gutter: 80px;
$section-gutter-md: 160px;

// Spacing
$grid-gutter-width:           30px !default;

// Max width (container) https://www.webnl.nl/blog/2012/04/1366-bij-768-pixels-de-populairste-schermresolutie
$container-max-widths: (
  xl: 1366px - ($grid-gutter-width * 2)
) !default;
  
// Colors
$brand-dark:                #082031;
$brand-light:               #F5F5F5;
$brand-primary:             #EE1F6E;
$brand-accent:              #FCBF10;
$brand-secondary:           #006B82;
$white:                     #FFF;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $brand-primary,
    "secondary":  $brand-secondary,
    "accent":     $brand-accent,
    "light":      $brand-light
  ),
  $theme-colors
);

// Base
$body-color:                $brand-dark !default;
$body-bg:                   $brand-light !default;

// Typo
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-medium:          500 !default;
$font-weight-bold:            700 !default;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875 !default;
$font-size-footer-address:    0.75rem;

$font-weight-base:            $font-weight-light !default;
$font-weight-links:           $font-weight-bold;
$headings-font-weight:        700 !default;

$h1-font-size:                $font-size-base * 3.125 !default;
$h2-font-size:                $font-size-base * 2.7 !default;
$h3-font-size:                $font-size-base * 2.275 !default;
$h4-font-size:                $font-size-base * 1.85 !default;
$h5-font-size:                $font-size-base * 1.425 !default;
$h6-font-size:                $font-size-base !default;

$h1-font-size-sm:             $font-size-base * 1.5 !default;
$h2-font-size-sm:             $font-size-base * 1.4 !default;
$h3-font-size-sm:             $font-size-base * 1.3 !default;
$h4-font-size-sm:             $font-size-base * 1.2 !default;
$h5-font-size-sm:             $font-size-base * 1.1 !default;
$h6-font-size-sm:             $font-size-base !default;

// Forms
$input-padding-x:             .5rem !default;

// Buttons
$input-btn-font-size: $font-size-sm !default;
$input-btn-padding-x:         2rem !default;
$input-btn-padding-y:         .5rem !default;
$btn-font-size:               $input-btn-font-size !default;
$btn-border-radius:           25px !default;
$btn-font-weight:             $font-weight-bold !default;
