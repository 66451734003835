﻿/// <reference path="../site.scss" />

.layout-infrastructure {

  background-color: $white;

  .col-map {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .img-thumbnail {
    margin-bottom: $grid-gutter-width;
  }

  .row-thumbs {
    padding-bottom: $grid-gutter-width;
  }
}
