/// <reference path="../../app.scss" />

%c-fallout {
    overflow: hidden;
}

.c-fallout {
    @extend %c-fallout;
    &--right {
        @extend %c-fallout;
        .bg-svg {
            //float: right;
        }
        @include media-breakpoint-down(sm) {
            .row {
                flex-direction: column-reverse;
            }
        }
    }
}

.c-fallout__svg-background img:not(.bg-reset) {
    top: $grid-gutter-width * 2;
    margin-bottom: $grid-gutter-width;
    left: 50%;
    height: 200px;
    position: relative;
    transform-origin: 50%;
    transform: translate(-50%, 0) scale(1.1);
    @include media-breakpoint-up(md) {
        position: absolute;
        margin-bottom: 0;
        width: 100%;
        height: auto;
        top: 50%;
        left: $grid-gutter-width/2;
        transform-origin: 0 50%;
        transform: translateY(-50%) scale(1.5);
    }
}

.c-fallout--right .c-fallout__svg-background img:not(.bg-reset) {
  //transform-origin: 50%;
  @include media-breakpoint-up(md) {
    transform-origin: 100% 50%;
    left: auto;
    right: $grid-gutter-width/2;
  }
}

.c-fallout__content {
    h2 {
        margin-top: -5px;
    }
    :last-child {
        margin-bottom: 0;
    }
    .justify-content-center {
        margin-bottom: -#{$grid-gutter-width/2};
        .btn.btn-primary {
            margin-bottom: $grid-gutter-width/2;
        }
    }
}
