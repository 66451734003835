﻿/// <reference path="../../app.scss" />

.contact-form hr {
  margin: $grid-gutter-width 0;
}

//-- Dropdown plugin selectr
.selectr-selected {
  border-color: $input-border-color;
  border-radius: $input-border-radius;
}

.js-quotation {
  display: none;
}

.pretty {
  margin-right: 0;
}

.form-control:focus {
  border-color: $brand-secondary;
  -webkit-box-shadow: 0 0 0 0.2rem rgba($brand-secondary, 0.25);
  box-shadow: 0 0 0 0.2rem rgba($brand-secondary, 0.25);
}

.form-control.error {
  border-color: $brand-primary;
  -webkit-box-shadow: 0 0 0 0.2rem rgba($brand-primary, 0.25);
  box-shadow: 0 0 0 0.2rem rgba($brand-primary, 0.25);
}

.pretty input.error + .state label {
  color: $brand-primary;

  &::before {
    border: 1px solid $brand-primary;
    -webkit-box-shadow: 0 0 0 0.2rem rgba($brand-primary, 0.25);
    box-shadow: 0 0 0 0.2rem rgba($brand-primary, 0.25);
  }
}
