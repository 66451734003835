/// <reference path="../site.scss" />

@include media-breakpoint-down(xs) {
    body {
        font-size: $font-size-sm;
    }
        
    h1, .h1 { @include font-size($h1-font-size-sm); }
    h2, .h2 { @include font-size($h2-font-size-sm); }
    h3, .h3 { @include font-size($h3-font-size-sm); }
    h4, .h4 { @include font-size($h4-font-size-sm); }
    h5, .h5 { @include font-size($h5-font-size-sm); }
    h6, .h6 { @include font-size($h6-font-size-sm); }

    .btn {
        width: 100%;
    }
}

body {
  overflow-x: hidden;
  padding-top: 75px; /* fixed navbar */
  &.stop-body-scroll {
    position: fixed;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
  }
}

h1 {
  margin-top: $section-gutter/2;
  @include media-breakpoint-up(md) {
    margin-top: $section-gutter-md/2;
  }
}

b, strong {
  font-weight: $font-weight-bold;
}

img {
  max-width: 100%;
}

.hr-dashed {
  border-style: dashed;
}

button:focus {
  outline: 0;
}

.container, .container-fluid {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}

.bg-secondary {
    color: $white;
    a:not(.btn) {
        color: $brand-accent;
    }
}

.btn i {
    margin-right: .5rem;
}

// Additional buttons
@each $color, $value in $theme-colors {
  .btn-transparent-#{$color} {
    @include button-outline-variant($value);
    border: none;
  }

  .btn-outline-#{$color} {
    color: inherit;
    font-weight: $font-weight-light;

    i {
      color: $value;
    }

    &:hover i {
      color: $white;
    }
  }
}

ul, ol, dl {
    padding-left: 2rem;
}


.bg-section-divider {
  width: 100%;
}


// Rotating loading svg
button.load-button {
  position: relative;

  span {
    transition: all 1s;
    opacity: 1;
  }

  svg {
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 1s;

    path, rect {
      fill: $white;
    }
  }
}

button.loading-start {
  span {
    opacity: 0;
  }

  svg {
    display: block;
    opacity: 1;
  }
}

.bg-brand-light {
  background-color: $brand-light;
}
