// Custom !default variables
@import './vendor/bootstrap-custom-variables';
// !!! Do not move this import into the shared variables
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,700');

// Custom !default variables
@import './vendor/bootstrap-custom-variables';

// Vendor
@import './vendor/bootstrap-node-modules';
@import './vendor/hamburgers-custom';
@import './vendor/google-recaptcha';
@import './vendor/plyr-custom';

// Vendor imports
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/pretty-checkbox/src/pretty-checkbox";

// AYS imports
@import './base/base';
@import './base/forms';
@import './layout/layout-shared';
@import './layout/layout-home';
@import './layout/layout-contact';
@import './layout/layout-generic';
@import './layout/layout-infrastructure';
@import './layout/layout-references';
@import './debugging/nesting-containers';
@import './components/c-nav-ecommaz';
@import './components/c-fallout';
@import './components/c-solution';

// Just the styleguide
@import './SrcScss/helpers/styleguide';

.img-overflower {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  max-width: none;

  @include media-breakpoint-up(lg) {
    width: 60vw;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  &.floaterright {
    float: right;
  }

  &.nofloater {
    margin-top: 0px;
    opacity: 1;
    margin-bottom: 30px;
  }
}

.img-overflower40 {
  position: relative;
  width: 100%;
  z-index: -1;
  margin-bottom: 30px;


  @include media-breakpoint-up(lg) {
    width: 40vw;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  &.floaterright {
    float: right;
  }

  &.nofloater {
    margin-top: 0px;
    margin-bottom: 30px;
  }
}
