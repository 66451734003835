/// <reference path="../site.scss" />

%l-sec-padding {
  padding-bottom: $section-gutter;

  @include media-breakpoint-up(md) {
    padding-bottom: $section-gutter-md;
  }
}

.l-sec-padding {
  @extend %l-sec-padding;
  padding-top: $section-gutter;

  @include media-breakpoint-up(md) {
    padding-top: $section-gutter-md;
  }
}

.l-sec-padding--with-divider {
  @extend %l-sec-padding;
  padding-top: $section-gutter / 1.5;

  @include media-breakpoint-up(md) {
    padding-top: $section-gutter-md/1.5;
  }
}

.l-sec-padding--half {
  margin: $section-gutter/2 0;

  @include media-breakpoint-up(md) {
    margin: $section-gutter-md/2 0;
  }
}

.l-testimonials {
    padding-top: $section-gutter;
    padding-bottom: $section-gutter;
    .fas:not(:last-of-type) {
        margin-right: .4rem;
    }
    .fas::before {
        color: $brand-accent;
    }
}

.l-footer {
    padding-top: 165px;
    background-color: white;
    background-image: url('/img/backgrounds/bg-locator.svg'), url('/img/backgrounds/bg-light.svg');
    background-position: center top, center top;
    background-repeat: no-repeat, no-repeat;
    background-size: auto 200px, 100% 60px;
    @include media-breakpoint-up(xl) {
        padding-top: 230px;
        background-position: calc(50% + 125px) top, center top;
        background-size: auto 450px, 100% 135px;
    }
}

.l-footer__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn {
        margin-top: $grid-gutter-width/4;
        margin-bottom: $grid-gutter-width/4;
        @include media-breakpoint-up(sm) {
            &:not(:last-of-type) {
                margin-right: $grid-gutter-width;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}

@include media-breakpoint-down(xs) {
    .l-footer__buttons {
        width: 100%;
    }
}

.l-footer__logo {
    .svg-logo {
        display: inline-block;
        max-width: 100%;
        height: 100px;
        @include media-breakpoint-down(md) {
            margin-top: $grid-gutter-width/2;
            margin-bottom: $grid-gutter-width/2;
        }
    }
}

.l-locations {
    padding: $grid-gutter-width 0;
    display: flex;
    justify-content: center;
    @include media-breakpoint-up(xl) {
        max-width: 900px;
    }
    @include media-breakpoint-up(lg) {
        justify-content: flex-start;
    }
}

.l-location__item {
  position: relative;
  margin: $grid-gutter-width/2 $grid-gutter-width / 2;

  @include media-breakpoint-down(lg) {
    text-align: center;
  }

  h5 {
    display: inline-block;
  }

  a {
    color: $brand-dark;
    text-decoration:none;
  }
}

.l-location__address {
    @include media-breakpoint-down(lg) { i { display: none; } }
    @include media-breakpoint-up(xl) {
    padding-left: 1.5rem;
    i {
            position: absolute;
            left: 0; 
            color: $brand-primary;
            &::before {
                position: relative;
                top: 2px;
            }
        }
    }
}

.l-testimonials {
  position: relative;
  h2{
    margin-bottom:0;
  }

  h3 { 
    font-size: 1.4rem;
    font-weight: 400;
  }
}

.l-testimonials div {
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-up(md) {
    width: calc(100% - 200px);
  }
}

.slick-arrow {
  position: absolute;
  top: 50%;
  padding: 0;
  width: 40px;
  height: 40px;
  font-size: 0;
  line-height: 0;
  border-radius: 20px;
  border: none;
  background-color: $white;
  box-shadow: 0 0 6px rgba(black, .16);

  @include media-breakpoint-down(md) {
    display: none !important;
  }

  &::before {
    display: absolute;
    top: 0;
    right: 0;
    color: $brand-dark;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 1rem;
    line-height: 1;

    opacity: .75;
  }
}

.slick-prev {
  left: 0;

  &::before {
    content: '\f104';
  }
}

.slick-next {
  right: 0;

  &::before {
    content: '\f105';
  }
}

.slick-rating {
  margin-bottom: 0;

  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

.slick-dots {
  margin-top: 10px;
  padding-left: 0;
  list-style: none;
  text-align: center;

  li {
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
  }

  button {
    padding: 0;
    width: 10px;
    height: 10px;
    color: white;
    background-color: rgba($brand-secondary, .4);
    border-radius: 5px;
    font-size: 0;
    line-height: 0;
    border: none;
  }

  .slick-active button {
    background-color: $brand-secondary;
  }
}


.row--largerpadding {
  margin-right: -30px;
  margin-left: -30px;

  > div[class^="col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
}


.load-hidden {
  visibility: hidden;
}
