/// <reference path="../site.scss" />

.guide-title {
  color: coral;
  font-size: 30px;

  + hr {
    background: coral;
  }
}

.guide-subtitle {
  margin: 10px 0;
  color: $gray-700;
  font-size: 15px;
  font-style: italic;
  .bg-secondary & {
    color: $gray-300;
  }
}


.guide-logo-wrap {
  display: inline-block;
  margin: 15px 0;
  width: 100%;
  padding: 15px 10px;
  img {
    height: 60px;
    &.logo--streng-th-icon-circle, &.logo--streng-th-icon {
      width: auto;
    }
  }
}

// .guide-logo-wrap {
//   &--neutrals-four {
//     @extend %guide-logo-wrap;
//     background-color: $neutrals-four;
//   }
//   &--colors-one {
//     @extend %guide-logo-wrap;
//     background-color: $colors-one;
//   }
//   &--colors-two {
//     @extend %guide-logo-wrap;
//     background-color: $colors-two;
//   }
//   &--black {
//     @extend %guide-logo-wrap;
//     background-color: black;
//   }
// }

.guide-swatch {
  margin-right: 10px;
  margin-bottom: 20px;
  display: inline-block;
  width: 100px;
  vertical-align: top;
}

.guide-info {
  margin: 5px 0;
  color: $gray-700;
  font-size: 12px;
  font-weight: $font-weight-normal;
  font-style: italic;
}

.guide-color {
  width: 100%;
  height: 100px;
}


.color-guide-border {
  border: 1px solid $gray-300;
}

// Swatches colors
.guide-color-one {
  background-color: $brand-primary;
}
.guide-color-two {
  background-color: $brand-secondary;
}
.guide-color-three {
  background-color: $brand-accent;
}
.guide-color-four {
  background-color: $brand-dark;
}

// Swatches neutrals
.guide-neutral-one {
  background-color: $white;
}
.guide-neutral-two {
  background-color: $brand-light;
}

// Swatches gradients
// .guide-gradient-one {
//   background-color: green;
//   background: $gradient-one;
// }

// only on the styleguide.html
// .container-guide:last-of-type {
//   // &:last-child {
//     margin-bottom: 50vh;
//   // }
//   .nav-link {
    
//     padding-left: 0;
//   }
// }

// .col-guide {
//   padding: 30px;
// }

// .bottomNav {
//   position: relative;
//   height: 35px;
//   z-index: 140;
//   .bottomNavButtonOFF {
//     float: left;
//     display: block;
//     width: 20px;
//     height: 20px;
//     border: 2px solid $colors-one;
//     margin-left: 9px;
//     cursor: pointer;
//     &.bottomNavButtonON {
//       background-color: $colors-two;
//       border-color: $colors-two;
//     }
//     .bg-dark & {
//       border-color: white;
//       &.bottomNavButtonON {
//         background-color: $colors-four;
//         border-color: $colors-four;
//       }
//     }
//   }
// }

.col-guide.bg-white, .col-guide.bg-secondary {
  .btn {
    margin-bottom: $grid-gutter-width / 2;
  }
}
