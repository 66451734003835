/// <reference path="../../vendor.scss" />

.plyr--youtube {
    border: 6px solid $brand-primary;
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
    background: lighten($brand-primary, 2%);
    color: #fff;
}

.plyr__control--overlaid {
    background: $brand-primary;
}

.plyr__control--overlaid:focus,
.plyr__control--overlaid:hover {
    background: lighten($brand-primary, 2%);
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
    background: $brand-primary;
}

.plyr--full-ui input[type=range] {
    -webkit-appearance: none;
    background: 0 0;
    border: 0;
    border-radius: 28px;
    color: $brand-primary;
    display: block;
    height: 20px;
    margin: 0;
    padding: 0;
    transition: box-shadow .3s ease;
    width: 100%
}



.plyr__control.plyr__tab-focus {
    box-shadow: 0 0 0 5px rgba($brand-primary,.5);
    outline: 0;
}
