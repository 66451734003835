/// <reference path="../site.scss" />

.l-home-hero {
  overflow: hidden;

  h1 {
    padding-bottom: $grid-gutter-width;

    @include media-breakpoint-up(xl) {
      padding-bottom: $grid-gutter-width;
      max-width: calc(80% - #{$grid-gutter-width});
    }

    span {
      font-size: 1rem;
      display: block;
      font-weight: 100;
      font-size: 1.3rem;
      line-height: 1.4rem;


      @include media-breakpoint-up(md) {
        font-size: 2.3rem;
        line-height: 2rem;
      }
    }
  }

  .wrap-groundplane {
    position: absolute;
    width: calc(100% - #{$grid-gutter-width});
    height: 100%;
    //border: 1px solid green;
    .bg-offset {
      width: 100%;
      padding-top: 37%;
      display: block;
    }

    .bg-groundplane {
      position: absolute;
      height: 100%;
      background-color: $white;
      left: -$grid-gutter-width;
      width: 100vw;

      @include media-breakpoint-up(md) {
        left: -$grid-gutter-width/2;
        transform: translateX(-50%);
      }

      z-index: -1;
    }
  }

  .row-video {
    //border: 1px solid coral;
    .btn {
      margin-bottom: $grid-gutter-width/2;
    }

    .btn:not(:last-of-type) {
      margin-right: $grid-gutter-width/2;
    }

    .bg-home-hero {
      position: relative;
      left: 50%;
      margin-bottom: $section-gutter/2;
      width: 120%;
      transform: translateX(-50%);

      @include media-breakpoint-up(md) {
        left: 0;
        width: 100%;
        transform-origin: left top;
        transform: scale(1.2);
      }
    }
  }

  .col-video :last-child {
    margin-bottom: $section-gutter;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}

.layout-home .slick-slider .draggable:hover {
  cursor: grab;
}
