/// <reference path="../site.scss" />

.container-fluid .container-fluid,
.container .container {
    &:after {
        content: 'Warning! You dont nest .container or .container-fluid';
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 30px;
        width: 100%;
        color: white;
        text-align: center;
        background-color: rgba(255, 68, 0, 0.90);
        z-index: 99999999;
    }
}
