/// <reference path="../../app.scss" />

.c-solution {
  margin-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width;

  &__title {
    color: $brand-secondary;
  }
}
